/* eslint-disable lines-between-class-members */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line max-classes-per-file
import crypto from 'crypto';
import { BackgroundQueryStorage } from './BackgroundQueryStorage';

/**
 * @typedef {Object} CacheConfig
 * @property {number} [expireIn] The cached data will expire in {expireIn} milliseconds
 * @property {boolean} [keepFresh] When cached data expires, a new fetch query will be sent
 * @property {function} [earlyFetch] Takes current variables and returns new variables
 * @property {function} [onRefresh] Called when the cached data is refreshed
 * @property {number} [nbSessionReloadsBeforeDestruction] Number of times the cached data
 * will be reloaded before being destroyed
 * @property {number} [onlyIfCalledAtLeastX] Only expire the cache if the query has been called at least X times
 * @property {function} [isExpired] Called when the cached data is about to expire
 * @property {function} [onExpired] Called when the cached data has expired
 * @property {string[]} [flags] Used for targetted cache expiration
 */

/**
 * ONLY INSTANCIATED ONCE
 */
setInterval(() => {
  BackgroundQueryCoordinator._refreshCache();
}, 10 * 1000);

/**
 * Cache config documentation:
 *  expireIn (number): The cached data will expire in {expireIn} milliseconds
 *  keepFresh (bool): When cached data expires, a new fetch query will be sent
 * !!EXPERIMENTAL!! UNSTABLE - earlyFetch (function):
 *      Takes current variables and returns new variables
 *      for a new fetch query that will be called on-the-spot
 *      Use this prop to fetch and cache data for queries that MIGHT come soon
 *      Example: When page 2 is queried, fetch and cache data for page 3 and 4 for
 *      when the user eventually goes to the next page
 *      Return an array of variables to send multiple early fetch
 */

export class BackgroundQueryFlags {
  static VESSEL = 'VESSEL';
  static FLEET = 'FLEET';
  static GROUP = 'GROUP';
  static ZONE = 'ZONE';
  static USER = 'USER';
  static PORT = 'PORT';
  static POLICY = 'POLICY';
  static TRANSIT = 'TRANSIT';
  static VOYAGE = 'VOYAGE';
  static FILTERS = 'FILTERS';
  static GENERIC_OVERVIEW = 'GENERIC_OVERVIEW';
  static CALCULATIONS = 'CALCULATIONS';
  static CALCULATIONS_MY = 'CALCULATIONS_MY';
  static CALCULATIONS_FLEET = 'CALCULATIONS_FLEET';
  static CALCULATIONS_MASTER = 'CALCULATIONS_MASTER';
}

export class BackgroundQueryCoordinator {
  /**
   * If true, will save memory usage by storing cache to the localstorage instead
   * and will retrieve it from localstorage whenever it is needed
   */
  static SAVE_MEMORY = true;

  // DO NOT MODIFY IT FROM OUTSIDE THIS CLASS
  // initializes from the localstorage, decrements the nbSessionReloadsBeforeDestruction and deletes
  // the entry if it reaches 0
  // However, queries that have nbSessionReloadsBeforeDestruction set to 0 (never saved to localstorage)
  // will be kept in memory since they should be ephemeral
  static queries = (() => {
    const cache = BackgroundQueryStorage.getStoredCache();
    const queries = {};

    for (const UUID in cache) {
      if (Object.hasOwnProperty.call(cache, UUID)) {
        const query = cache[UUID];

        if (query.config.nbSessionReloadsBeforeDestruction === 0) {
          BackgroundQueryStorage.remove(UUID);
          continue;
        }

        if (query.config.nbSessionReloadsBeforeDestruction > 0) {
          query.config.nbSessionReloadsBeforeDestruction -= 1;
          BackgroundQueryStorage.set(UUID, query);
        }

        queries[UUID] = query;
      }
    }

    if (BackgroundQueryCoordinator.SAVE_MEMORY) return {};
    return queries;
  })();

  static _getFullQuery(UUID) {
    // search through the queries in memory first, then the localstorage
    return BackgroundQueryCoordinator.queries[UUID] || BackgroundQueryStorage.get(UUID);
  }

  static _getFullQueries() {
    // if save memory, we need to get queries from the localstorage
    const QUERIES = BackgroundQueryCoordinator.SAVE_MEMORY
      ? BackgroundQueryStorage.getStoredCache()
      : BackgroundQueryCoordinator.queries;

    // if save memory, we need to also include the queries stored in memory
    if (BackgroundQueryCoordinator.SAVE_MEMORY) {
      for (const UUID in BackgroundQueryCoordinator.queries) {
        if (Object.hasOwnProperty.call(BackgroundQueryCoordinator.queries, UUID)) {
          QUERIES[UUID] = BackgroundQueryCoordinator.queries[UUID];
        }
      }
    }

    return QUERIES;
  }

  static _refreshCache() {
    const QUERIES = BackgroundQueryCoordinator._getFullQueries();
    for (const UUID in QUERIES) {
      if (Object.hasOwnProperty.call(QUERIES, UUID)) {
        const query = QUERIES[UUID];
        if (!query.__cache.expired) {
          if (query.config.expireIn > 0 && new Date().getTime() - query.__cache.createdAt > query.config.expireIn) {
            if (query.config.onlyIfCalledAtLeastX > 0) {
              if (query.__cache.called >= query.config.onlyIfCalledAtLeastX) {
                BackgroundQueryCoordinator.expired(UUID);
                continue;
              }
            } else {
              BackgroundQueryCoordinator.expired(UUID);
              continue;
            }
          }

          if (query.config.isExpired) {
            if (query.config.isExpired(query)) {
              BackgroundQueryCoordinator.expired(UUID);
              continue;
            }
          }
        }
      }
    }
  }

  static declare(UUID, data, config = {}) {
    // console.log('BackgroundLazyQuery:', 'declared', UUID);
    const defaultConfig = {
      expireIn: -1,
      nbCallsBeforeDestruction: -1,
      nbSessionReloadsBeforeDestruction: 0,
      flags: [],

      /**
       * ==== EVENT CALLBACKS ====
       */
      /**
       * Called when the cache has expired.
       * Use for cleanup
       */
      onExpired: undefined,

      /**
       * INTERNAL USE ONLY! Use keepFresh prop to enable automatic refresh
       * (Keep alive behavior)
       *
       * Called when the cache is about to expire.
       * If defined, will keep the data in the cache, but will expect that
       * new data will be declared.
       *
       * Use to keep a background task to update the cache permanently
       * NOTICE: Defining this will mean that the cache will NEVER be deleted
       */
      onRefresh: undefined,
    };

    const newQuery = {
      config: {
        ...defaultConfig,
        ...config,
      },
      __cache: {
        called: 0,
        expired: false,
        createdAt: new Date().getTime(),
      },
      payload: data,
    };

    if (
      newQuery.config.nbSessionReloadsBeforeDestruction > 0
      || newQuery.config.nbSessionReloadsBeforeDestruction === -1
    ) {
      // if the query survives a session reload, we save it to the localstorage
      BackgroundQueryStorage.set(UUID, newQuery);

      // if the memory saving mode is disabled, we also save it to the memory
      if (BackgroundQueryCoordinator.SAVE_MEMORY !== true) BackgroundQueryCoordinator.queries[UUID] = newQuery;
    } else {
      // if the query is ephemeral, we only save it to the memory
      BackgroundQueryCoordinator.queries[UUID] = newQuery;
    }
  }

  static delete(UUID) {
    delete BackgroundQueryCoordinator.queries[UUID];
    BackgroundQueryStorage.remove(UUID);
  }

  static expireListeners = [];
  static addExpireListener(callback, flags = []) {
    if (!Array.isArray(flags)) flags = [flags];
    BackgroundQueryCoordinator.expireListeners.push({ callback, flags });
  }

  static removeExpireListener(callback) {
    BackgroundQueryCoordinator.expireListeners = BackgroundQueryCoordinator.expireListeners.filter((v) => v.callback !== callback);
  }

  static purge() {
    BackgroundQueryCoordinator.queries = {};
    BackgroundQueryStorage.purge();
  }

  static expiredFlag(flags = []) {
    if (!Array.isArray(flags)) flags = [flags];

    const QUERIES = BackgroundQueryCoordinator._getFullQueries();

    for (const UUID in QUERIES) {
      if (Object.hasOwnProperty.call(QUERIES, UUID)) {
        const query = QUERIES[UUID];
        if (!query.__cache.expired) {
          // console.log('flags', query.config.flags);
          if (query.config.flags.some((v) => flags.includes(v))) {
            BackgroundQueryCoordinator.expired(UUID, false);
          }
        }
      }
    }

    // calls the expire listeners
    console.log('CacheListener.onCacheChange', 'expiredFlag', flags);
    BackgroundQueryCoordinator.expireListeners.forEach((v) => {
      if (v.flags.length === 0 || v.flags.some((flag) => flags.includes(flag))) {
        v.callback(flags);
      }
    });
  }

  static expired(UUID, callListeners = true) {
    const query = BackgroundQueryCoordinator._getFullQuery(UUID);

    query.__cache.expired = true;

    // updates the localstorage if the query is saved to the localstorage
    if (BackgroundQueryStorage.get(UUID)) {
      // console.log('BackgroundLazyQuery:', 'expired (in storage)', UUID);
      BackgroundQueryStorage.set(UUID, query);
    }

    // updates the query in memory if the query is saved to the memory
    if (BackgroundQueryCoordinator.queries[UUID]) {
      BackgroundQueryCoordinator.queries[UUID] = query;
    }

    // console.log('BackgroundLazyQuery:', 'expired', UUID);
    if (query.config.onRefresh) {
      query.config.onRefresh();
    } else {
      BackgroundQueryCoordinator.delete(UUID);
      if (query.config.onExpired) query.config.onExpired();
    }

    // calls the expire listeners
    if (callListeners) {
      console.log('CacheListener.onCacheChange', 'UUID');
      BackgroundQueryCoordinator.expireListeners.forEach((v) => {
        if (v.flags.length === 0 || v.flags.some((flag) => query.config.flags.includes(flag))) {
          v.callback(query.config.flags);
        }
      });
    }
  }

  static getUUID(query, options, callingCompoName) {
    function hash(str) {
      // return str;
      return crypto.createHash('md5').update(str).digest('hex');
    }
    return `${hash(callingCompoName)}_${hash(JSON.stringify(query))}_${hash(JSON.stringify(options))}`;
  }

  static get(UUID) {
    const query = BackgroundQueryCoordinator._getFullQuery(UUID);

    if (query) {
      if (query.config.nbCallsBeforeDestruction > 0) {
        if (query.__cache.called >= query.config.nbCallsBeforeDestruction) {
          BackgroundQueryCoordinator.expired(UUID);
          return undefined;
        }

        query.__cache.called += 1;
        (async () => {
          BackgroundQueryStorage.set(UUID, query);
        })();
      }
      // console.log("QUERY:", UUID, "called", query.__cache.called)
      return query;
    }
  }
}
