import { gql } from '@apollo/client';

export const CREATE_ZONEGROUP = gql`
  mutation CreateGroup($groupDetails: GroupInput!) {
    createGroup(groupDetails: $groupDetails) {
      message
      status
    }
  }
`;

export const UPDATE_ZONEGROUP = gql`
  mutation UpdateGroup($updateGroupId: ID!, $groupDetails: GroupInput!) {
    updateGroup(id: $updateGroupId, groupDetails: $groupDetails) {
      message
      status
    }
  }
`;

export const UPDATE_ZONEGROUP_STATUS = gql`
  mutation UpdateGroupStatus($updateGroupStatusId: ID!, $status: Boolean!) {
    updateGroupStatus(id: $updateGroupStatusId, status: $status) {
      data {
        id
        name
        status
        scheduled
        activeFrom
        activeTo
        active
        zones
      }
      message
      status
    }
  }
`;

export const REMOVE_ZONEGROUP = gql`
  mutation RemoveGroup($removeGroupId: ID!) {
    removeGroup(id: $removeGroupId) {
      message
      status
    }
  }
`;

export const GET_ALL_GROUPS_FOR_INVITE_USER = gql`
  query GetGroups {
    getGroups {
      data {
        id
        name
      }
      status
      message
    }
  }
`;

export const GET_ALL_GROUPS_MINIMIZED = gql`
  query GetGroups {
    getGroups {
      data {
        id
        name
      }
      status
      message
    }
  }
`;

export const GET_GROUPS_DASHBOARD = gql`
  query GetGroups(
    $paginator: Paginator
    $sorter: Sorter
    $search: String
    $filters: Filters
  ) {
    getGroups(
      paginator: $paginator
      sorter: $sorter
      search: $search
      filters: $filters
    ) {
      data {
        id
        name
        status
        scheduled
        activeFrom
        activeTo
        zones {
          id
          name
          type
          status
          seasonal
          activeFrom
          activeTo
          active
          alias
          group
        }
        policyCount
        active
      }
      paginationInfo {
        totalItems
        limit
        currentPage
        totalPages
        nextPage
        prevPage
        hasNextPage
        hasPrevPage
      }
      status
      message
    }
  }
`;

export const GET_GROUPS = gql`
  query GetGroups(
    $paginator: Paginator
    $sorter: Sorter
    $search: String
    $filters: Filters
  ) {
    getGroups(
      paginator: $paginator
      sorter: $sorter
      search: $search
      filters: $filters
    ) {
      data {
        id
        name
        status
        scheduled
        activeFrom
        activeTo
        zones {
          id
          name
          type
          status
          seasonal
          activeFrom
          activeTo
          active
          coordinates {
            lat {
              direction
              value
            }
            lng {
              direction
              value
            }
          }
          alias
          group
        }
        policyCount
        active
      }
      paginationInfo {
        totalItems
        limit
        currentPage
        totalPages
        nextPage
        prevPage
        hasNextPage
        hasPrevPage
      }
      status
      message
    }
  }
`;

export const CREATE_ZONE = gql`
  mutation CreateZone($zoneDetails: ZoneInput!) {
    createZone(zoneDetails: $zoneDetails) {
      message
    }
  }
`;

export const REMOVE_ZONE = gql`
  mutation RemoveZone($removeZoneId: ID!) {
    removeZone(id: $removeZoneId) {
      message
      status
    }
  }
`;

export const UPDATE_ZONE = gql`
  mutation UpdateZone($updateZoneId: ID!, $zoneDetails: ZoneInput!) {
    updateZone(id: $updateZoneId, zoneDetails: $zoneDetails) {
      message
    }
  }
`;

export const GET_GROUPS_FOR_FILTER = gql`
  query GetGroupsZoneCreation {
    getGroups {
      data {
        id
        name
        scheduled
        activeFrom
        activeTo
        active
      }
    }
  }
`;

export const GET_GROUPS_FOR_ZONE_CREATION = gql`
  query GetGroupsZoneCreation(
    $paginator: Paginator!
    $sorter: Sorter!
    $filters: Filters
    $search: String
  ) {
    getGroups(
      paginator: $paginator
      sorter: $sorter
      filters: $filters
      search: $search
    ) {
      data {
        id
        name
        scheduled
        activeFrom
        activeTo
        active
      }
    }
  }
`;

export const GET_ALIASES = gql`
  query GetAliases {
    getAliases {
      data {
        alias
        name
        type
      }
    }
  }
`;

export const GET_GROUP = gql`
  query GetGroup($getGroupId: ID!) {
    getGroup(id: $getGroupId) {
      data {
        activeFrom
        activeTo
        id
        name
        scheduled
        status
        zones
      }
    }
  }
`;

export const GET_ZONES_PAGINATED = gql`
  query GetZonesPaginated(
    $paginator: Paginator!
  ) {
    getZones(
      paginator: $paginator
    ) {
      data {
        id
        name
        type
        status
        seasonal
        activeFrom
        activeTo
        active
        group
        alias
        hasMinimumIceClass
        coordinates {
          lat {
            direction
            value
          }
          lng {
            direction
            value
          }
        }
      }
      paginationInfo {
        totalItems
        limit
        currentPage
        totalPages
        nextPage
        prevPage
        hasNextPage
        hasPrevPage
      }
      status
      message
    }
  }
`;

export const GET_ZONES_FOR_GROUP_FORM = gql`
  query GetZones {
    getZones {
      data {
        id
        name
        type
        status
        seasonal
        activeFrom
        activeTo
        active
        group
        alias
        hasMinimumIceClass
      }
      message
      status
    }
  }
`;

export const GET_ZONES = gql`
  query GetZones {
    getZones {
      data {
        id
        name
        type
        status
        seasonal
        activeFrom
        activeTo
        active
        group
        alias
        hasMinimumIceClass
        coordinates {
          lat {
            direction
            value
          }
          lng {
            direction
            value
          }
        }
      }
      message
      status
    }
  }
`;

export const GET_ZONE = gql`
  query GetZone($getZoneId: ID!) {
    getZone(id: $getZoneId) {
      data {
        id
        name
        type
        status
        seasonal
        activeFrom
        activeTo
        active
        group
        alias
        hasMinimumIceClass
        coordinates {
          lat {
            value
            direction
          }
          lng {
            direction
            value
          }
        }
      }
    }
  }
`;
