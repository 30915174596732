import { gql } from '@apollo/client';

const GET_PROFILE = gql`
  query GetProfile {
    getProfile {
      data {
        uid
        fullname
        firstname
        lastname
        email
        role
        assignedFleet
        assignedGroup
        assignedVessel
        preferences {
          acquisitionMode
          dashboardGroupSelection
        }
        digest
        report
        MFA {
          key
          recoveryKeys
          imageUrl
          setupCompleted
        }
        digestZones
        outstandingQuotes
        reportZones
        statusUpdate
      }
      status
      message
    }
  }
`;

const GET_PASSWORD_HISTORY = gql`
  query GetPasswordHistory($email: String!, $password: String!) {
    getPasswordHistory(email: $email, password: $password) {
      status
      message
    }
  }
`;

const CREATE_USER = gql`
  mutation Mutation($userDetails: UserInput!) {
    inviteUser(userDetails: $userDetails) {
      status
      message
    }
  }
`;

const SEND_INVITE_MAIL = gql`
  mutation SendInviteEmail($userId: ID!, $email: String!) {
    sendInviteEmail(userId: $userId, email: $email) {
      message
      status
    }
  }
`;

const GET_USER = gql`
  query GetUser($userId: ID!) {
    getUser(userId: $userId) {
      data {
        uid
        fullname
        firstname
        lastname
        email
        role
        assignedFleet
        assignedGroup
        assignedVessel
        digest
        report
      }
    }
  }
`;

const UPDATE_USER_PROFILE = gql`
  mutation Mutation($userDetails: ProfileInput) {
    updateProfile(userDetails: $userDetails) {
      status
      message
    }
  }
`;

const UPDATE_USER = gql`
  mutation Mutation($userId: ID!, $userDetails: UserInput!) {
    updateUser(userId: $userId, userDetails: $userDetails) {
      message
      status
    }
  }
`;

const DELETE_USER = gql`
  mutation Mutation($userId: ID!) {
    deleteUser(userId: $userId) {
      status
      message
    }
  }
`;

const GET_USERS_FOR_FILTER = gql`
  query Query {
    getUsers {
      data {
        uid
        email
        fullname
        roleName
        status
      }
      status
      message
    }
  }
`;

const GET_USERS_FOR_OVERVIEW = gql`
  query Query(
    $paginator: Paginator!
    $sorter: Sorter!
    $filters: UserFilters
    $search: String
  ) {
    getUsers(
      paginator: $paginator
      sorter: $sorter
      filters: $filters
      search: $search
    ) {
      data {
        uid
        email
        fullname
        roleName
        status
      }
      paginationInfo {
        totalItems
        limit
        currentPage
        totalPages
        nextPage
        prevPage
        hasNextPage
        hasPrevPage
      }
      status
      message
    }
  }
`;

const UPDATE_PROFILE = gql`
  mutation UpdateProfile($userDetails: ProfileInput) {
    updateProfile(userDetails: $userDetails) {
      message
      status
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      message
      status
    }
  }
`;

const REGENERATE_2FA_RECOVERY = gql`
  mutation RegenerateRecoveryKeys {
    regenerateRecoveryKeys {
      data
      message
      status
    }
  }
`;

const GENERATE_QR_SECRET = gql`
  mutation GenerateQRandSecret($temporary: Boolean!) {
    generateQRandSecret(temporary: $temporary) {
      data {
        imageUrl
        recoveryKeys
        key
      }
      message
      status
    }
  }
`;

const VALIDATE_TOTP_SETUP = gql`
  mutation ValidateTOTPSetup($code: String!, $temporary: Boolean!) {
    validateTOTPSetup(code: $code, temporary: $temporary) {
      data
      message
      status
    }
  }
`;

const VALIDATE_TOTP = gql`
  mutation ValidateTOTP($code: String!) {
    validateTOTP(code: $code) {
      message
      status
    }
  }
`;

const UPDATE_USER_PREFERENCES = gql`
  mutation UpdateUser($preferences: UserPreferencesInput!) {
    updatePreferences(preferences: $preferences) {
      message
      status
    }
  }
`;

export {
  GET_USERS_FOR_OVERVIEW,
  GET_USERS_FOR_FILTER,
  GET_PROFILE,
  GET_PASSWORD_HISTORY,
  UPDATE_PROFILE,
  CHANGE_PASSWORD,
  REGENERATE_2FA_RECOVERY,
  GENERATE_QR_SECRET,
  VALIDATE_TOTP_SETUP,
  VALIDATE_TOTP,
  CREATE_USER,
  UPDATE_USER_PROFILE,
  DELETE_USER,
  GET_USER,
  UPDATE_USER,
  SEND_INVITE_MAIL,
  UPDATE_USER_PREFERENCES,
};
