import Loadable from 'react-loadable';
import Loading from './components/common-ui/loading';

export const AUTHROUTE = 'signin';
export const ADMINROUTE = 'admin';

const getComponent = (component) => Loadable({
  loader: () => import(`./containers/${component}/${component}`),
  loading: Loading,
});

export default [
  {
    key: 'auth',
    layout: 'AuthLayout',
    exact: true,
    path: '/auth/:mode?/:resetOrInvite?',
    component: getComponent('auth'),
    needsPermission: false,
  },
  {
    key: 'second-factor',
    layout: 'TfaLayout',
    exact: true,
    path: '/second-factor',
    component: getComponent('second-factor'),
  },
  {
    key: 'register',
    layout: 'AuthLayout',
    exact: true,
    path: '/signup/:inviteId',
    component: getComponent('auth'),
    needsPermission: false,
  }, {
    key: 'authentication',
    layout: 'TfaLayout',
    exact: true,
    path: '/authentication',
    component: getComponent('twofactor'),
    needsPermission: false,
  }, {
    key: 'dashboard',
    label: 'Dashboard',
    layout: 'DefaultLayout',
    exact: true,
    path: '/dashboard/:latitude?/:longitude?/:imo?',
    component: getComponent('dashboard'),
    needsPermission: false,
  }, {
    key: 'dashboard-root',
    label: 'Dashboard',
    layout: 'DefaultLayout',
    exact: true,
    path: '/',
    component: getComponent('dashboard'),
    needsPermission: false,
  },
  {
    key: 'voyages',
    label: 'Voyages',
    layout: 'DefaultLayout',
    exact: true,
    path: '/voyages/:voyageId?/:vesselId?/:zoneId?',
    component: getComponent('voyages'),
    needsPermission: true,
  },
  {
    key: 'calculations',
    label: 'Calculations',
    layout: 'DefaultLayout',
    exact: true,
    path: '/calculations/:calculationTemplateId?',
    component: getComponent('calculations'),
    needsPermission: true,
  },
  {
    key: 'vessels',
    label: 'Vessels',
    layout: 'DefaultLayout',
    exact: true,
    path: '/vessels/:vesselId?',
    component: getComponent('vessels'),
    menu: true,
    needsPermission: true,
  }, {
    key: 'fleets',
    label: 'Fleets',
    layout: 'DefaultLayout',
    exact: false,
    path: '/fleets/:fleetId?',
    component: getComponent('fleets'),
    needsPermission: true,
  },
  {
    key: 'zones',
    label: 'Risk Zones',
    layout: 'DefaultLayout',
    exact: true,
    path: '/zones/:zoneOrGroup?/:zoneOrGroupId?',
    component: getComponent('zones'),
    needsPermission: true,
  }, {
    key: 'users',
    label: 'Users',
    layout: 'DefaultLayout',
    exact: true,
    path: '/users/:userId?',
    component: getComponent('users'),
    needsPermission: true,
  },
  {
    key: 'ports',
    label: 'Ports',
    layout: 'DefaultLayout',
    exact: true,
    path: '/ports/:portId?',
    component: getComponent('ports'),
    needsPermission: true,
  }, {
    key: 'policies',
    label: 'Policies',
    layout: 'DefaultLayout',
    exact: true,
    path: '/policies/:policyId?',
    component: getComponent('policies'),
    needsPermission: true,
  }, {
    key: 'declarations',
    label: 'Risk Zone Entry & Exit',
    layout: 'DefaultLayout',
    exact: true,
    path: '/declarations',
    component: getComponent('declarations'),
    needsPermission: true,
  }, {
    key: 'staff',
    label: 'Staff',
    layout: 'DefaultLayout',
    exact: true,
    path: '/staff',
    component: getComponent('staff'),
    needsPermission: true,
  },
  {
    key: 'useraccount',
    label: 'User Account',
    layout: 'DefaultLayout',
    exact: true,
    path: '/useraccount',
    component: getComponent('useraccount'),
    needsPermission: false,
  },
];
